@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext");
.pcnone {
  display: none !important;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block !important;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  overflow: hidden;
  counter-reset: number 0;
  line-height: 2em;
  font-weight: 400;
  color: #333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  letter-spacing: 0.05em;
  font-size: 16px;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  margin: 0 auto;
  max-width: 1160px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 80px;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #1e78c4;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

.recruit_index h1 {
  background: #4ab7b9;
  color: #fff !important;
  padding: 1em;
  border-radius: 2px;
}

@media screen and (max-width: 640px) {
  .recruit_index h1 {
    margin-left: 0 !important;
  }
}

.recruit_index h2 {
  font-size: 2em;
}

.recruit_index h2 a {
  color: #333 !important;
  font-weight: bold;
}

.recruit_index .recruit_main_text {
  background: #f3f3f3;
  padding: 20px;
}

.recruit_index .recruit_nav a {
  background: #4ab7b9;
  color: #fff !important;
  display: block;
  max-width: 300px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 2px;
  font-weight: bold;
  font-size: 1em;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 68px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed !important;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
}

#header {
  position: relative;
  z-index: 11;
  padding: 10px 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 1200px) {
  #header {
    padding: 10px;
  }
}

@media only screen and (max-width: 834px) {
  #header {
    display: block;
    padding: 0;
  }
}

#header .flxL {
  width: auto;
}

@media only screen and (max-width: 834px) {
  #header .flxL #siteID {
    position: fixed;
    top: 4px;
    margin: 0 !important;
    right: 10px;
    z-index: 12;
    height: 30px;
    width: 50%;
  }
}

#header .flxL #siteID a {
  text-align: left;
  display: block;
}

@media only screen and (max-width: 834px) {
  #header .flxL #siteID a {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    height: 55px;
  }
}

#header .flxL #siteID img {
  vertical-align: middle;
  height: 55px !important;
}

@media only screen and (max-width: 834px) {
  #header .flxL #siteID img {
    max-width: 100%;
  }
}

#header .flxR {
  width: 60%;
}

#hednav .navwrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

#hednav .navwrap #nav_global {
  width: 50%;
}

@media only screen and (max-width: 1200px) {
  #hednav .navwrap #nav_global {
    width: 70%;
  }
}

@media only screen and (max-width: 834px) {
  #hednav .navwrap #nav_global {
    background: inherit;
    border-bottom: 0;
    box-shadow: none;
  }
}

#hednav .navwrap #nav_global ul {
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  #hednav .navwrap #nav_global ul {
    padding: 10px;
  }
}

#hednav .navwrap #nav_global ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-grow: 1;
}

@media only screen and (max-width: 834px) {
  #hednav .navwrap #nav_global ul li {
    border-left: 0;
  }
}

#hednav .navwrap #nav_global ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: 700;
  line-height: 1.4em;
  color: #222 !important;
  padding: 20px 0;
  font-size: 16px;
  font-size: 0.83333vw;
}

@media only screen and (min-width: 1600px) {
  #hednav .navwrap #nav_global ul li a {
    font-size: 1em;
  }
}

@media only screen and (max-width: 1200px) {
  #hednav .navwrap #nav_global ul li a {
    font-size: 1em;
  }
}

#hednav .navwrap #nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#hednav .navwrap #hed_info {
  width: 20%;
}

@media only screen and (max-width: 1200px) {
  #hednav .navwrap #hed_info {
    width: 30%;
  }
}

#hednav .navwrap #hed_info .store1 p a {
  padding: 4.5%;
  display: block;
  border-radius: 50px;
  color: #fff;
  background: #4ab7b9;
  font-size: 16px;
  font-size: 0.83333vw;
}

@media only screen and (min-width: 1600px) {
  #hednav .navwrap #hed_info .store1 p a {
    font-size: 1em;
  }
}

@media only screen and (max-width: 1200px) {
  #hednav .navwrap #hed_info .store1 p a {
    font-size: 1em;
  }
}

@media only screen and (max-width: 834px) {
  #hednav .navwrap #hed_info .store1 p a {
    padding: 20px;
    border-radius: 0;
  }
}

#hednav .navwrap #hed_info .store1 p a i {
  margin-left: 15px;
}

#hednav .navwrap #hed_info .store2 p a {
  padding: 4.5%;
  display: block;
  border-radius: 50px;
  color: #fff;
  background: #4ab7b9;
  font-size: 16px;
  font-size: 0.83333vw;
}

@media only screen and (min-width: 1600px) {
  #hednav .navwrap #hed_info .store2 p a {
    font-size: 1em;
  }
}

@media only screen and (max-width: 1200px) {
  #hednav .navwrap #hed_info .store2 p a {
    font-size: 1em;
  }
}

@media only screen and (max-width: 834px) {
  #hednav .navwrap #hed_info .store2 p a {
    border-radius: 0;
  }
}

#hednav .navwrap #hed_info .store2 p a i {
  margin-left: 15px;
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 834px) {
  .global-menu-btn {
    background: #f4f7f6;
    border-right: 1px solid #E6EAEA;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 67px;
    width: 68px;
    z-index: 11;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #000;
    line-height: 1;
    transition: 1s;
  }
  .global-menu-btn:hover {
    color: #fff;
    background: #008aff;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    width: 68px;
    height: 67px;
    background: #f4f7f6;
    border-left: 1px solid #E6EAEA;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    color: #000;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  .navdamy {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  #hednav {
    justify-content: center;
    height: 68px;
    width: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    margin: 0;
    padding: 10px 0;
    background: #eef2f2;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #eef2f2;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    border-bottom: 1px solid #E6EAEA;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #hed_info .store1 {
    border: 1px solid #ccc;
    background: #f7f7f7;
    margin-bottom: 15px;
  }
  #hednav .wrap .navwrap #hed_info .store1 i {
    font-weight: 700;
    margin-left: 7px;
    font-size: 1.5rem !important;
  }
  #hednav .wrap .navwrap #hed_info .store1 a {
    color: #fff !important;
  }
  #hednav .wrap .navwrap #hed_info .store1 .add {
    width: 100%;
    font-size: 0.8em;
  }
  #hednav .wrap .navwrap #hed_info .store1 .txt {
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #hednav .wrap .navwrap #hed_info .store1 .txt img {
    margin-right: 7px;
  }
  #hednav .wrap .navwrap #hed_info .store2 a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4ab7b9;
    color: #fff;
    padding: 20px;
    font-size: 1.5em;
  }
  #hednav .wrap .navwrap #hed_info .store2 a:hover {
    opacity: 1 !important;
    background: #d66202;
  }
  #hednav .wrap .navwrap #hed_info .store2 a i {
    font-size: 1.9em;
    margin-right: 7px;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    transition: 1s;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #E6EAEA;
  }
  #hednav .wrap .navwrap #nav_global ul li ul {
    display: block !important;
    position: static;
    height: auto !important;
  }
  #hednav .wrap .navwrap #nav_global ul li ul li a {
    color: #fff !important;
  }
  #hednav .wrap .navwrap #nav_global ul li ul li a:before {
    display: none;
  }
  #hednav .wrap .navwrap #nav_global ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    line-height: 1;
    height: 100%;
    padding: 24px 36px;
    font-size: 1em;
    font-weight: bold;
    color: #202121 !important;
    cursor: pointer;
  }
  #hednav .wrap .navwrap #nav_global ul li a:hover {
    background: #ccc;
  }
  #hednav .wrap .navwrap #nav_global ul li a span {
    display: none;
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

@keyframes mainscl {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes mainscl2 {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}

#mainArea {
  position: relative;
  width: 100%;
  background: #fff;
}

#mainArea > img {
  width: 100% !important;
  height: auto;
  vertical-align: middle;
}

#mainArea .txt {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5%;
}

#mainArea .txt img {
  margin-bottom: 0px;
  max-width: 400px;
}

#mainArea .txt h1 {
  text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.7);
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1.7em;
  color: #fff;
  font-weight: 400;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  font-size: 60px;
  font-size: 3.125vw;
}

@media only screen and (min-width: 1600px) {
  #mainArea .txt h1 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 1000px) {
  #mainArea .txt h1 {
    font-size: 2.5em;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #mainArea .txt h1 {
    font-size: 2.3em;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea .txt h1 {
    font-size: 1.5em;
  }
}

#mainArea .txt h1 span {
  letter-spacing: 0.15em;
  margin-top: 30px;
  display: block;
  font-size: 60px;
  font-size: 3.125vw;
}

@media only screen and (min-width: 1600px) {
  #mainArea .txt h1 span {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 1000px) {
  #mainArea .txt h1 span {
    font-size: 0.7em;
  }
}

.local-keyvisual-bg {
  background-color: #333 !important;
}

#local-keyvisual {
  position: relative;
  background: #333;
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual h1 {
  z-index: 1;
  position: relative;
  color: #fff;
  text-align: center;
  text-shadow: 0px 3px 9.7px rgba(0, 0, 0, 0.35);
  padding: 150px 0;
  line-height: 1;
  font-size: 3em;
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual h1 {
    font-size: 3em;
    padding: 100px 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 3em;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 2.5em;
    padding: 100px 0;
  }
}

#local-keyvisual h1:empty {
  display: none;
}

#local-keyvisual h1 span {
  margin-bottom: 15px;
  display: block;
  font-size: 50px;
  font-size: 2.60417vw;
}

@media only screen and (min-width: 1600px) {
  #local-keyvisual h1 span {
    font-size: 0.7em;
  }
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual h1 span {
    font-size: 0.7em;
  }
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 span {
    font-size: 0.6em;
  }
}

#local-keyvisual h1 div {
  font-weight: 700;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan1 {
  margin: 30px 0;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #222;
}

footer#global_footer {
  padding: 30px 0 0 0;
  width: 100%;
  min-width: 100% !important;
  height: auto;
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer .inner #siteID {
  margin-bottom: 30px;
}

#footer .inner #siteID a {
  text-align: center;
}

#footer .inner #siteID img {
  vertical-align: middle;
  height: 55px !important;
}

#footer .inner #nav_footer {
  margin-bottom: 20px;
}

@media only screen and (max-width: 834px) {
  #footer .inner #nav_footer {
    display: none;
  }
}

#footer .inner #nav_footer ul {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#footer .inner #nav_footer ul li a {
  padding: 0 15px;
  font-size: 0.8em;
  color: #000;
}

#footer .inner #nav_footer ul li:last-child a {
  border-left: 1px solid #000;
}

#copy {
  text-align: center;
  font-size: 0.8em;
  display: block;
  line-height: 50px;
  color: #fff;
  background: #4ab7b9;
}

@media only screen and (max-width: 640px) {
  #copy {
    text-align: center;
  }
}

#copy a {
  font-size: 0.8em;
  color: #fff;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.progress-wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 90px;
  width: 90px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  background: #fff;
}

.progress-wrap::after {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  content: '\f062';
  font-weight: 700;
  text-align: center;
  font-size: 1.9em;
  color: #4ab7b9;
  left: 0;
  top: 0;
  height: 90px;
  width: 90px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap::before {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  content: '\f062';
  font-weight: 700;
  text-align: center;
  font-size: 1.9em;
  color: #4ab7b9;
  opacity: 0;
  left: 0;
  top: 0;
  height: 90px;
  width: 90px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg {
  z-index: 1;
  position: relative;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #4ab7b9;
  stroke-width: 5;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

body.light .progress-wrap {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
}

body.light .progress-wrap::after {
  color: #1f2029;
}

body.light .progress-wrap svg.progress-circle path {
  stroke: #1f2029;
}

.switch,
.circle {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switch {
  width: 80px;
  height: 4px;
  border-radius: 27px;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  position: fixed;
  right: 50px;
  top: 75px;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  z-index: 33333;
  transition: all 300ms linear;
}

.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4b4d64;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}

.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 1.3em;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: #ff8695;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 1.3em;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #f8ab37;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switched .circle {
  left: 45px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #1f2029;
}

.switched .circle:before {
  opacity: 0;
}

.switched .circle:after {
  opacity: 1;
}

.cont_1 {
  margin: 80px auto;
  max-width: 1200px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .cont_1 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 {
    flex-direction: column-reverse;
  }
  .cont_1 img {
    width: 20% !important;
    max-width: 100% !important;
    float: none;
    margin: 20px auto 0;
  }
}

.cont_1 > div {
  display: flex;
  align-items: center;
  height: auto;
  letter-spacing: 0.05em;
  writing-mode: vertical-rl;
  font-weight: 100;
  line-height: 3em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #221815;
  font-size: 1em;
  max-height: 30em;
  height: 100vw;
}

@media only screen and (max-width: 1200px) {
  .cont_1 > div {
    line-height: 2em;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 > div {
    line-height: 1.9em;
    writing-mode: inherit;
    text-align: center !important;
    display: block;
    max-height: inherit;
    height: auto;
  }
}

.cont_2wrap .cont_2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto 80px;
}

@media only screen and (max-width: 1200px) {
  .cont_2wrap .cont_2 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2wrap .cont_2 {
    margin: 30px auto 0px;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.cont_2wrap .cont_2.mg01 .flxR {
  margin-top: -7%;
}

@media only screen and (max-width: 834px) {
  .cont_2wrap .cont_2.mg01 .flxR {
    margin-top: 0;
  }
}

.cont_2wrap .cont_2:nth-child(odd) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .cont_2wrap .cont_2:nth-child(odd) {
    flex-direction: column;
  }
}

.cont_2wrap .cont_2:nth-child(odd) .flxR {
  margin-left: -15%;
}

@media only screen and (max-width: 834px) {
  .cont_2wrap .cont_2:nth-child(odd) .flxR {
    margin-left: 0;
  }
}

.cont_2wrap .cont_2:nth-child(even) .flxR {
  margin-right: -15%;
}

@media only screen and (max-width: 834px) {
  .cont_2wrap .cont_2:nth-child(even) .flxR {
    margin-right: 0;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2wrap .cont_2:last-child {
    margin: 30px auto 30px;
  }
}

.cont_2wrap .cont_2 .flxL {
  width: 60%;
}

@media only screen and (max-width: 1000px) {
  .cont_2wrap .cont_2 .flxL {
    width: 60%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2wrap .cont_2 .flxL {
    margin-bottom: 30px;
    width: 100%;
  }
}

.cont_2wrap .cont_2 .flxL .midashi h2 {
  color: #000;
  margin-bottom: 20px;
}

.cont_2wrap .cont_2 .flxL b {
  line-height: 1.7em;
  display: inline-block;
  margin-bottom: 40px;
  color: #000;
  font-size: 20px;
  font-size: 1.04167vw;
}

@media only screen and (min-width: 1600px) {
  .cont_2wrap .cont_2 .flxL b {
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_2wrap .cont_2 .flxL b {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 640px) {
  .cont_2wrap .cont_2 .flxL b {
    font-size: 1.2em;
  }
}

.cont_2wrap .cont_2 .flxR {
  width: 50%;
}

@media only screen and (max-width: 1000px) {
  .cont_2wrap .cont_2 .flxR {
    width: 50%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2wrap .cont_2 .flxR {
    width: 100%;
  }
}

.cont_2wrap .cont_2 .flxR img {
  width: 100% !important;
}

.cont_2wrap .cont_2 .flxR .img {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  flex-direction: row !important;
}

@media only screen and (max-width: 834px) {
  .cont_2wrap .cont_2 .flxR .img {
    box-shadow: inherit;
  }
}

.cont_2wrap .cont_2 .flxR .img:before {
  position: relative;
  z-index: 1;
  content: "";
  padding-top: 100%;
  display: block;
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .cont_2wrap .cont_2 .flxR .img:before {
    display: none;
  }
}

.cont_2wrap .cont_2 .flxR .img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

@media only screen and (max-width: 834px) {
  .cont_2wrap .cont_2 .flxR .img img {
    position: static;
  }
}

.cont_3 {
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cont_3 .slider2 {
  width: 66.6666%;
  height: 100%;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .slider2 {
    width: 100%;
  }
}

.cont_3 .slider2 .box:before {
  position: relative;
  z-index: 1;
  content: "";
  padding-top: 55%;
  display: block;
  width: 100% !important;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .slider2 .box:before {
    padding-top: 45%;
  }
}

.cont_3 .slider2 .box img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.cont_3 .slider2 .owl-dots {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.cont_3 .slider2 .owl-dots button {
  margin: 0 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
}

.cont_3 .slider2 .owl-dots button.active {
  background: #4ab7b9;
}

.cont_3 .box01 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.3333%;
  padding: 7% 3%;
  color: #fff;
  background: #444444;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .box01 {
    width: 50%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .box01 {
    width: 100%;
  }
}

.cont_3 .box01 table {
  display: block !important;
}

.cont_3 .box01 table td {
  display: block !important;
  padding: 0 !important;
  border: 0 !important;
}

.cont_3 .box01 table h2 {
  margin-bottom: 20px;
  font-size: 30px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .cont_3 .box01 table h2 {
    font-size: 1.9em;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_3 .box01 table h2 {
    font-size: 1.9em;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .box01 table h2 {
    font-size: 1.4em;
  }
}

.cont_3 .box02 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.3333%;
  padding: 7% 5%;
  color: #fff;
  background: #4ab7b9;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .box02 {
    width: 50%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .box02 {
    width: 100%;
  }
}

.cont_3 .box02 table {
  display: block !important;
}

.cont_3 .box02 table td {
  display: block !important;
  padding: 0 !important;
  border: 0 !important;
}

.cont_3 .box02 table h2 {
  margin-bottom: 20px;
  font-size: 30px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .cont_3 .box02 table h2 {
    font-size: 1.9em;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_3 .box02 table h2 {
    font-size: 1.9em;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .box02 table h2 {
    font-size: 1.4em;
  }
}

.cont_3 .box03 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.3333%;
  padding: 7% 5%;
  color: #fff;
  background: #444444;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .box03 {
    width: 50%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .box03 {
    width: 100%;
  }
}

.cont_3 .box03 table {
  display: block !important;
}

.cont_3 .box03 table td {
  display: block !important;
  padding: 0 !important;
  border: 0 !important;
}

.cont_3 .box03 table h2 {
  margin-bottom: 20px;
  font-size: 30px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .cont_3 .box03 table h2 {
    font-size: 1.9em;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_3 .box03 table h2 {
    font-size: 1.9em;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .box03 table h2 {
    font-size: 1.4em;
  }
}

.cont_3 .box04 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.3333%;
  padding: 7% 5%;
  color: #fff;
  background: #4ab7b9;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .box04 {
    width: 50%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .box04 {
    width: 100%;
  }
}

.cont_3 .box04 table {
  display: block !important;
}

.cont_3 .box04 table td {
  display: block !important;
  padding: 0 !important;
  border: 0 !important;
}

.cont_3 .box04 table h2 {
  margin-bottom: 20px;
  font-size: 30px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .cont_3 .box04 table h2 {
    font-size: 1.9em;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_3 .box04 table h2 {
    font-size: 1.9em;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .box04 table h2 {
    font-size: 1.4em;
  }
}

.btn {
  margin-top: 30px;
}

@media only screen and (max-width: 834px) {
  .btn {
    display: block;
  }
}

.btn:after {
  display: none;
}

.btn a {
  color: #fff;
  opacity: 1 !important;
  display: block;
  line-height: 1;
  padding: 20px 10px;
  border: 1px solid #fff;
  border-radius: 50px;
}

.btn a:hover {
  color: #4ab7b9;
  background: white;
}

.btn a i {
  margin-left: 15px;
}

/* title */
.midashi h2 {
  font-weight: 600;
  line-height: 1.4em;
  color: #111;
  font-size: 1.5em;
}

@media only screen and (max-width: 1200px) {
  .midashi h2 {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi h2 {
    font-size: 1.6em;
  }
}

@media only screen and (max-width: 640px) {
  .midashi h2 {
    font-size: 1.3em;
  }
}

.midashi2 h2 {
  letter-spacing: 0.2em;
  padding: 20px 15px;
  font-weight: 700;
  color: #111;
  background: #f4f4f4;
  border-left: solid 5px #4ab7b9;
  border-bottom: solid 3px #d7d7d7;
  font-size: 1.5em;
}

@media only screen and (max-width: 1200px) {
  .midashi2 h2 {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi2 h2 {
    font-size: 1.6em;
  }
}

@media only screen and (max-width: 640px) {
  .midashi2 h2 {
    font-size: 1.5em;
  }
}

.midashi3 h3 {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #333;
  font-weight: 700;
  line-height: 1;
  color: #111;
  font-size: 20px;
  font-size: 1.04167vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h3 {
    font-size: 1.4em;
  }
}

@media only screen and (max-width: 1200px) {
  .midashi3 h3 {
    font-size: 1.4em;
  }
}

@media only screen and (max-width: 1000px) {
  .midashi3 h3 {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 640px) {
  .midashi3 h3 {
    font-size: 1.1em;
  }
}

.member02 {
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-left: -2.439024390243902%;
}

@media only screen and (max-width: 834px) {
  .member02 {
    margin-left: 0;
    justify-content: space-between;
  }
}

.member02 .box {
  width: 30.89430894308943%;
  margin-left: 2.439024390243902%;
  margin-bottom: 2.439024390243902%;
}

@media only screen and (max-width: 640px) {
  .member02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.works01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.works01:nth-child(even) {
  flex-direction: row-reverse !important;
}

@media only screen and (max-width: 834px) {
  .works01 {
    display: block !important;
  }
}

.works01 .box:first-child {
  position: relative;
  width: 45%;
}

@media only screen and (max-width: 834px) {
  .works01 .box:first-child {
    width: 100%;
  }
}

.works01 .box:first-child:before {
  position: relative;
  z-index: 1;
  content: "";
  padding-top: 100%;
  display: block;
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .works01 .box:first-child:before {
    display: none;
  }
}

.works01 .box:first-child img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

@media only screen and (max-width: 834px) {
  .works01 .box:first-child img {
    position: static;
  }
}

.works01 .box:last-child {
  width: 50%;
}

@media only screen and (max-width: 834px) {
  .works01 .box:last-child {
    width: 100%;
    margin-top: 30px;
  }
}

.works02 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.works02:nth-child(odd) {
  flex-direction: row-reverse !important;
}

@media only screen and (max-width: 834px) {
  .works02 {
    display: block !important;
  }
}

.works02 .box:first-child {
  position: relative;
  width: 45%;
}

@media only screen and (max-width: 834px) {
  .works02 .box:first-child {
    width: 100%;
  }
}

.works02 .box:first-child:before {
  position: relative;
  z-index: 1;
  content: "";
  padding-top: 100%;
  display: block;
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .works02 .box:first-child:before {
    display: none;
  }
}

.works02 .box:first-child img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

@media only screen and (max-width: 834px) {
  .works02 .box:first-child img {
    position: static;
  }
}

.works02 .box:last-child {
  width: 50%;
}

@media only screen and (max-width: 834px) {
  .works02 .box:last-child {
    width: 100%;
    margin-top: 30px;
  }
}

.company01 dt, .company01 dd {
  background: none !important;
  border: none !important;
  padding: 1.5em 1em !important;
  font-size: 1rem;
}

.company01 dt {
  border-bottom: 1px solid #ccc !important;
}

.company01 dd {
  border-bottom: 1px solid #eee !important;
}

@media only screen and (max-width: 640px) {
  .company01 dl {
    display: flex;
    flex-flow: column;
  }
  .company01 dt {
    border-bottom: 0 !important;
    background: #f9f9f9 !important;
    font-weight: bold;
  }
  .company01 dt, .company01 dd {
    padding: 5px !important;
    display: block;
    width: 100% !important;
  }
}

.staff01 {
  background: #f7f7f7;
  padding: 20px;
  border: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.staff01 .flxL {
  width: 35%;
}

@media only screen and (max-width: 640px) {
  .staff01 .flxL {
    width: 100%;
    margin-bottom: 20px;
  }
}

.staff01 .flxL .img {
  position: relative;
}

.staff01 .flxL .img img {
  width: 100% !important;
  height: 100% !important;
}

.staff01 .flxR {
  width: 60%;
}

@media only screen and (max-width: 640px) {
  .staff01 .flxR {
    width: 100%;
  }
}

.staff01 .box td, .staff01 .box tr {
  border: 0 !important;
  display: block !important;
  padding: 0 !important;
}

.staff01 .katagaki > div {
  margin-bottom: 0.5em;
}

@media only screen and (max-width: 640px) {
  .staff01 .katagaki > div {
    font-size: 1rem;
  }
}

.staff01 .name > div {
  margin-bottom: 40px;
  font-weight: 600;
  line-height: 1;
  color: #111;
  font-size: 2em;
}

@media only screen and (max-width: 1200px) {
  .staff01 .name > div {
    font-size: 2.2em;
  }
}

@media only screen and (max-width: 640px) {
  .staff01 .name > div {
    font-size: 2.2em;
  }
}

.staff01 .txt {
  margin-bottom: 20px;
}

.staff01 .txt > div {
  background: rgba(204, 204, 204, 0.2);
  padding: 25px !important;
}

@media only screen and (max-width: 640px) {
  .staff01 .txt > div {
    padding: 15px !important;
  }
}

.staff01 .btn {
  margin-top: 0px;
}

@media only screen and (max-width: 834px) {
  .staff01 .btn {
    display: block;
  }
}

.staff01 .btn:after {
  display: none;
}

.staff01 .btn a {
  background: #333;
  color: #fff;
  opacity: 1 !important;
  display: inline-block;
  line-height: 1;
  padding: 20px 70px;
  border-radius: 50px;
}

.staff01 .btn a:hover {
  background: #4ab7b9;
}

.staff01 .btn a i {
  margin-left: 15px;
}

.staff02 {
  background: #f7f7f7;
  padding: 20px;
  border: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.staff02:nth-child(odd) {
  flex-direction: row-reverse !important;
}

.staff02 .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .staff02 .flxL {
    width: 100%;
    margin-bottom: 20px;
  }
}

.staff02 .flxL .img {
  position: relative;
}

.staff02 .flxL .img:before {
  position: relative;
  z-index: 1;
  content: "";
  padding-top: 80%;
  display: block;
  width: 100% !important;
}

.staff02 .flxL .img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.staff02 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .staff02 .flxR {
    width: 100%;
  }
}

.staff02 .box td, .staff02 .box tr {
  border: 0 !important;
  display: block !important;
  padding: 0 !important;
}

.staff02 .katagaki > div {
  margin-bottom: 0.5em;
}

@media only screen and (max-width: 640px) {
  .staff02 .katagaki > div {
    font-size: 1rem;
  }
}

.staff02 .name > div {
  margin-bottom: 40px;
  font-weight: 600;
  line-height: 1;
  color: #111;
  font-size: 35px;
  font-size: 1.82292vw;
}

@media only screen and (min-width: 1600px) {
  .staff02 .name > div {
    font-size: 2.2em;
  }
}

@media only screen and (max-width: 1200px) {
  .staff02 .name > div {
    font-size: 2.2em;
  }
}

@media only screen and (max-width: 640px) {
  .staff02 .name > div {
    font-size: 2.2em;
  }
}

.staff02 .txt {
  margin-bottom: 20px;
}

.staff02 .txt > div {
  background: rgba(204, 204, 204, 0.2);
  padding: 25px !important;
}

@media only screen and (max-width: 640px) {
  .staff02 .txt > div {
    padding: 15px !important;
  }
}

.staff02 .btn {
  margin-top: 0px;
}

@media only screen and (max-width: 834px) {
  .staff02 .btn {
    display: block;
  }
}

.staff02 .btn:after {
  display: none;
}

.staff02 .btn a {
  background: #333;
  color: #fff;
  opacity: 1 !important;
  display: inline-block;
  line-height: 1;
  padding: 20px 70px;
  border-radius: 50px;
}

.staff02 .btn a:hover {
  background: #4ab7b9;
}

.staff02 .btn a i {
  margin-left: 15px;
}

@media only screen and (max-width: 640px) {
  .recruit_01 dl:first-child {
    display: none;
  }
}

.recruit_01 dl:first-child dt, .recruit_01 dl:first-child dd {
  background: #eee !important;
  color: #333;
  padding: 0.5em !important;
  border-bottom: none !important;
}

@media only screen and (max-width: 640px) {
  .recruit_01 dl {
    flex-direction: column;
    background: #f9f9f9;
    padding: 15px;
    margin-bottom: 30px;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2);
  }
}

.recruit_01 dt, .recruit_01 dd {
  border: none !important;
  background: #fff !important;
  border-bottom: 1px solid #ccc !important;
  font-size: 1rem;
  padding: 2em 0.5em !important;
}

@media only screen and (max-width: 640px) {
  .recruit_01 dt, .recruit_01 dd {
    padding: 0.5em 1em !important;
    border-bottom: 0 !important;
    background: transparent !important;
  }
}

.recruit_01 dt {
  width: 30% !important;
}

@media only screen and (max-width: 640px) {
  .recruit_01 dt {
    width: 100% !important;
    display: flex;
    align-items: center !important;
  }
}

.recruit_01 dt > span {
  background: #333;
  display: inline-block;
  padding: 0.2em 2.3em;
  border-radius: 3px;
  color: #fff;
}

.recruit_01 dd {
  width: 70% !important;
}

@media only screen and (max-width: 640px) {
  .recruit_01 dd {
    width: 100% !important;
  }
}

.recruit_01 dd a.btn_r {
  display: block;
  background: #333;
  width: 250px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  margin-right: 0;
  margin-left: auto;
  color: #fff !important;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .recruit_01 dd a.btn_r {
    margin: 0 auto 0px;
  }
}

.recruit_01 dd a.btn_r:after {
  content: "\f054";
  font-family: "font awesome 5 pro";
  position: absolute;
  top: 50%;
  right: 10px;
  line-height: 1;
  font-size: 13px;
  transform: translateY(-50%);
}

.member01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.member01:nth-child(even) {
  flex-direction: row-reverse !important;
}

.member01 .box:first-child {
  position: relative;
  width: 35%;
}

@media only screen and (max-width: 640px) {
  .member01 .box:first-child {
    width: 100%;
  }
}

.member01 .box:first-child img {
  width: 100% !important;
  height: 100% !important;
}

.member01 .box:last-child {
  width: 60%;
}

@media only screen and (max-width: 640px) {
  .member01 .box:last-child {
    width: 100%;
    margin-top: 30px;
  }
}

.member01 .box article h2 {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #333;
  font-weight: 700;
  line-height: 1;
  color: #333;
  font-size: 30px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .member01 .box article h2 {
    font-size: 1.9em;
  }
}

@media only screen and (max-width: 1200px) {
  .member01 .box article h2 {
    font-size: 1.7em;
  }
}

@media only screen and (max-width: 640px) {
  .member01 .box article h2 {
    font-size: 1.7em;
  }
}

.contactwrap {
  background: #f7f7f7;
  padding: 50px;
}

.policy a {
  color: #333;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 834px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}

.recruit_title h1 {
  margin-top: 0 !important;
}

@media only screen and (max-width: 640px) {
  .recruit_title ul + a + a {
    right: 0;
  }
}

@media only screen and (max-width: 640px) {
  .recruit_title ul + a {
    right: 110px;
  }
}

@media only screen and (max-width: 640px) {
  .recruit {
    padding: 15px !important;
    padding-top: 50px !important;
    margin: 15px 0 15px !important;
  }
}

.recruit h1 {
  color: #333;
  background: #f9f9f9;
  padding: 1.5em 2em;
  position: relative;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .recruit h1 {
    padding: 0.5em 1em;
    font-size: 1.2em;
  }
}

.recruit h1:before {
  content: "";
  display: block;
  width: 5px;
  height: 15px;
  border-radius: 500px;
  background: #333;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.recruit .recruit_title > a {
  color: #fff !important;
  background: rgba(51, 51, 51, 0.8);
  height: 60px;
  font-size: 1rem;
  letter-spacing: 0.1em;
}

.recruit .recruit_title > a br {
  display: none;
}

.recruit .recruit_title ul li {
  background: #333;
  color: #fff;
  border: none;
  padding: 0.2em 0.5em;
  font-size: 1rem;
}

@media only screen and (max-width: 640px) {
  .recruit .recruit_title ul li {
    margin-top: 0.5em;
    margin-right: 3%;
    width: 30%;
    text-align: center;
  }
  .recruit .recruit_title ul li:nth-child(3n) {
    margin-right: 0;
  }
}

.recruit .recruit_gists {
  background: #f3f3f3;
  padding: 40px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 640px) {
  .recruit .recruit_gists {
    padding: 15px;
  }
}

.recruit .recruit_gists h3 {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5em;
}

.recruit .recruit_gists h3:before {
  content: "\f064";
  font-family: "font awesome 5 pro";
  display: inline-block;
  margin-right: 0.3em;
}

.recruit .recruit_gists img {
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 640px) {
  .recruit .recruit_gists img {
    margin-bottom: 15px !important;
    display: block;
  }
}

.recruit .recruit_link a {
  display: block;
  width: 250px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #fff !important;
  background: #333;
  position: relative;
  margin: 0 auto;
}

.recruit .recruit_link a:after {
  content: "\f054";
  font-family: "font awesome 5 pro";
  position: absolute;
  top: 50%;
  right: 10px;
  line-height: 1;
  font-size: 13px;
  transform: translateY(-50%);
}

.recruit .recruit_detail dl {
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding: 2em 1em !important;
  align-items: center;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .recruit .recruit_detail dl {
    padding: 2em 0 !important;
  }
}

@media screen and (max-width: 640px) {
  .recruit .recruit_detail dl {
    align-items: flex-start;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .recruit .recruit_detail dt, .recruit .recruit_detail dd {
    width: 100% !important;
    min-width: 100% !important;
  }
}

.recruit .recruit_form dt {
  width: 30% !important;
  max-width: 30% !important;
}

@media only screen and (max-width: 640px) {
  .recruit .recruit_form dt {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.recruit .recruit_form dd {
  width: 70% !important;
  max-width: 70% !important;
}

@media only screen and (max-width: 640px) {
  .recruit .recruit_form dd {
    width: 100% !important;
    max-width: 100% !important;
  }
}
